import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Cta from "../components/service/Cta";
import WorkCollaboration from "../components/about/WorkCollaboration";
import SwiperTabs from "../components/SwiperTabs";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import ContentCommon from "../components/hire/ContentCommon";
import Reports from "../components/hire/Reports";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `Hire Web Designer`,
    text: "Get Web Design Experts",
    content: "Discover a new expanse of possibilities to transform your business by hiring dedicated web designers from Octet.",
    list: [
      'Flexible Hiring Options',
      'Cross-functional Designer',
      'Time-Zone Friendly'
    ],
    linkText: 'Hire Web Designer',
    link: '/contact-us/'
  };
  const commonContent = {
    title: 'Hire Professional Web Designer to Hypercharge Your Project',
    text: "Our website designers for hire are prepared to build you a fantastic website that will expand and produce noticeable outcomes.",
    list: [
      {
        title: 'Professional Design',
        text: 'Hire professional web designer who brings expertise and creativity to the table, ensuring your website stands out with a unique and polished look.'
      },
      {
        title: 'Enhance User Experience',
        text: 'Our web designers for hire understand how to structure content, design user-friendly interfaces, and implement responsive design to ensure the website works seamlessly across all devices.'
      },
      {
        title: 'Performance Optimization',
        text: 'Our web design experts design websites with clean code, fast loading times, and mobile-friendliness, all essential for search engine ranking.'
      }
    ],
    image: ["hire-content-web.webp"],
    titleText: 'Web Designs',
    alt: 'Web Designers from Octet provides diverse web design solutions.'
  }
  const commonContent2 = {
    title: 'Get Octet’s Top Web Designer for Hire',
    text: "Leading companies trust Octet Design Studio to Hire web application designers to acquire top web design expertise with industry-specific experience.",
    list: [
      {
        title: 'Hire Website Developer In 48 Hours',
        text: 'Our top web developers for hire ensure swift recruitment processes, bringing aboard and hiring the best UX designers within 48 hours.'
      },
      {
        title: 'Transparent Reporting',
        text: 'Stay in control with detailed timesheet reports from our web design specialists to track progress and productivity.'
      },
      {
        title: '160 hours of Dedicated Work',
        text: 'Our web designer for hire commits 160 hours of focused, high-quality work, ensuring productivity and results.'
      },
      {
        title: 'Seamless Collaboration',
        text: 'Hire dedicated web designer and enjoy seamless collaboration with a dedicated manager who oversees your design needs and ensures a smooth workflow.'
      }
    ],
    image: ["hire-content-2-web.webp"],
    titleText: 'Best Web Designers',
    alt: 'Why you should hire web designers from Octet'
  }
  const commonContent3 = {
    title: `3 Steps to Hire UI UX Designer`,
    text: "Experience the Octet advantage by hiring web designers in three easy steps.",
    list: [
      {
        title: 'Step 1: Share Your Requirements',
        text: "Provide us with your project requirements, and we will share the resumes of our web designers who best match your needs and business goals."
      },
      {
        title: 'Step 2: Interview Designers',
        text: "Interview and evaluate our skilled Web Design Experts to choose the most suitable candidate for your project's vision and goals."
      },
      {
        title: 'Step 3: Onboard And Begin',
        text: "Once you close and hire web designer, onboard them in the next 48 hours and dive into your project with their expertise and dedication."
      },
    ],
    image: ["hire-content-3-web.webp"],
    titleText: 'Hire Web Designer',
    alt: 'Simple steps to hire web designers from Octet'
  }
  // const commonContent4 = {
  //   title: 'Hire Designers As Per Your Requirement',
  //   text: "At Octet, you have the freedom to choose your designer with flexible recruitment options. Select the hiring model that best suits your needs.",
  //   list: [
  //     {
  //       title: 'Hire on Fixed Cost Basis',
  //       text: "Get your project completed within a predetermined budget, ensuring cost-effectiveness and transparency."
  //     },
  //     {
  //       title: 'Hire on Time & Material Basis',
  //       text: "Flexibility is key with this model, pay for the actual hours worked and materials used, ideal for dynamic projects."
  //     },
  //     {
  //       title: 'Hire as a Full-time',
  //       text: "Secure a dedicated designer for ongoing projects, providing consistent support and expertise as part of your team."
  //     },
  //   ],
  //   image: ["hire-content-4.webp"],
  //   titleText: 'Hire UI UX Designer Flexibly',
  //   alt: 'Flexible Hiring Models to Hire UI UX Designers from our design agency'
  // }
  const cta = {
    title: `Finding the Best Web Designers for Hire?`,
    contactText: 'Contact Us'
  };
  const content = [
    {
      title: "eCommerce Web Designers",
      content: "Our web designers use various tools and software to create designs that meet users' needs and help convert visitors into customers. Hire web designers to create an enjoyable and seamless shopping experience for customers, which can increase sales and customer loyalty.",
    },
    {
      title: "Web Designers for SaaS",
      content:
        "SaaS app Web Designers specializing in SaaS platforms create visually appealing and user-friendly designs for web and mobile applications. Hiring web designers will prove to be creative problem solvers to create beautiful and functional software that enhances the user experience.",
    },
    {
      title: "Enterprise Web Design experts",
      content: "Hiring website developers will allow you to deliver scalable solutions for large-scale operations. These designers will address intricate workflows and compliance needs with precision and user-centric design. Our web design specialists will work closely with developers and product managers to ensure the final product meets the enterprise's and its users' needs.",
    },
  ];
  const tabsData = {
    title: `Hire Web Designer with Core Competencies`,
    des: `Hiring web designers can help you create a website that works well on all platforms and offers engaging user interfaces, interactive experiences, and improved user experiences.`,
    tabs: ["Web App Designing ", "Web Portal Designing", "Responsive Website Designing", "Custom Website Designing"],
    slides: [
      {
        title: 'Web App Designing',
        desc1: "Hire web application designers to prioritize optimizing screens to accommodate significant information while ensuring the application remains fully functional.",
        desc2: 'Our web designers for hire understand the importance of user experience and can tailor the web app to meet the specific needs of your target audience.',
        list: [
          'Strong focus on user experience',
          'Efficient in communicating brand message through design',
          'Prompt on latest design trends and technologies'
        ]
      },
      {
        title: 'Web Portal Designing',
        desc1: "Our website designers for hire specialize in offering a wide range of comprehensive solutions for creating reliable, secure, and user-friendly web portals.",
        desc2: 'Our dedicated team of experienced professionals works closely with your organization to understand and fulfill your business needs and objectives.',
        list: [
          'Delivering Multi-Platform Compatibility',
          'Efficient in Designing Secure and Reliable Portals',
          'Proficiency in Close Collaboration'
        ]
      },
      {
        title: 'Responsive Website Designing',
        desc1: "Our web designers for hire prioritize creating visually appealing and user-friendly websites that adapt seamlessly to various screen sizes and device types.",
        desc2: 'We specialize in crafting responsive designs that are mobile-friendly and optimized to deliver an exceptional user experience.',
        list: [
          'Ability to Deliver Competitive Advantage',
          'Boosting Increased Engagement',
          'Creating Design for Improved SEO Performance'
        ]
      },
      {
        title: 'Custom Website Designing',
        desc1: "Hire web designers to create stunning and captivating custom websites suited to your requirements.",
        desc2: "Together, you and our talented web design experts will create a website that functions and looks fantastic.",
        list: [
          'Efficient in Customized Design Solutions',
          'Delivering Mobile Responsiveness',
          'Enhancing User Experience'
        ]
      }
    ],
    altOne: 'Web App Designing',
    titleOne: 'Web App Designing',
    altTwo: 'Web Portal Designing',
    titleTwo: 'Web Portal Designing',
    altThree: 'Responsive Website Designing',
    titleThree: 'Responsive Website Designing',
    altFour: 'Custom Website Designing',
    titleFour: 'Custom Website Designing',
    images: [
      'our-ui-ux.webp',
      'our-ui-ux-2.webp',
      'our-ui-ux-3.webp',
      'our-ui-ux-4.webp'
    ]
  };
  const projectData = [
    {
      title: "Landscape Pro",
      desc: "Simplifying Crew Management Process",
      Industry: "SaaS",
      firstAlt: "UI UX Design of a Schedule Screen to Manage Property",
      firstTitle: "Schedule Screen UI Design",
      secondAlt: "UI UX Design to Manage and Track User's Status",
      secondTitle: "UI Screen to Manage Users",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Design Audit",
          link: "/design-audit/",
        },
      ],
      link: "/project/landscape-pro/",
      images: ["landscape-pro.webp", "landscape-pro-2.webp", "landscape-pro-3.webp"]
    },
    {
      title: "Survey2Connect",
      desc: "Streamlining Survey Collection Solution",
      Industry: "Enterprise",
      firstAlt: "UI UX Design To Create Survey",
      firstTitle: "Survey Creation Screen",
      secondAlt: "Ui Design To Create Questionnaire For Survey",
      secondTitle: "Questionnaire For Survey",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/survey2connect/",
      images: ["survey.webp", "survey-2.webp", "survey-3.webp"],
    },
    {
      title: "Elastic Sign",
      desc: "Efficient Design for Secure Document Signing",
      Industry: "SaaS",
      firstAlt: "UI Design to View all the Documents and their Status",
      firstTitle: "Manage Documents UI Design",
      secondAlt: "UI UX Design to Sign Documents Digitally",
      secondTitle: "UI Design to Sign Digital Documents",
      thirdAlt: "UI Screen to draw, upload or type your own signature",
      thirdTitle: "Create Your Own Signature",
      WhatWeDid: [
        {
          title: "User Research",
          link: "/user-research/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "React Development",
          link: "/reactjs-development/",
        },
      ],
      link: "/project/elastic-sign",
      images: ["elastic-sign.webp", "elastic-sign-2.webp", "elastic-sign-3.webp"]
    },
    {
      title: "Unilogix App",
      desc: "Comprehensive Logistic Analytics Suite",
      Industry: "Logistics",
      firstAlt: "Mobile UI UX Screen Of Logistics Insight Application",
      firstTitle: "Logistics Services List",
      secondAlt: "Order And Warehouse Details",
      secondTitle: "Order Details",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "UI Development",
          link: "/ui-development/",
        },
      ],
      link: "/project/logistics-insight-app-design/",
      images: ["uni-logics.webp", "uni-logics-2.webp", "uni-logics-3.webp"],
    },
    {
      title: "Ship Delight",
      desc: "User-Centric Logistic Website Redesign",
      Industry: "Logistics",
      firstAlt: "UI UX Dashbaord To Check The Real Time Shipment Status",
      firstTitle: "Real Time Shipment Status",
      secondAlt: "UI Dashboard To Check The Performance Status",
      secondTitle: "NDR Status",
      thirdAlt: "UI UX design to view detailed information about users' properties",
      thirdTitle: "UI Design to Manage Properties",
      WhatWeDid: [
        {
          title: "Branding",
          link: "/branding/",
        },
        {
          title: "UI UX Designing",
          link: "/ui-ux-designing/",
        },
        {
          title: "Wordpress Development",
          link: "/wordpress-development/",
        },
      ],
      link: "/project/shipdelight/",
      images: ["ship-delight-web.webp", "ship-delight-web-2.webp", "ship-delight-web-3.webp"],
    },
  ];
  const lastLength = projectData;
  const itemsList = [
    {
      title: '200+',
      text: 'Successful Projects '
    },
    {
      title: '30+',
      text: 'Experienced Designers'
    },
    {
      title: '80+',
      text: 'Satisfied Clients'
    }
  ];
  const reports = {
    title: `Hire Designers from <span class="h1-span">Octet vs Others</span>`,
    text: "Choosing Octet means efficient execution and a speedier ROI. Our web designers for hire are intensely passionate and committed to creating captivating designs and responsive experiences.",
    listHead: [
      'Aspects',
      'Octet’s UI UX Designers',
      'Other Designers'
    ],
    list: [
      {
        title: 'Hiring Time',
        first: '2 Days',
        second: 'Long Lead Time'
      },
      {
        title: 'Hiring Model',
        first: 'Flexible Options',
        second: 'Limited Options'
      },
      {
        title: 'Timezone',
        first: 'Overlap',
        second: 'No Overlap'
      },
      { title: 'Web Design Expertise', second:'Uncertain' },
      { title: 'Pre-screen Candidate' },
      { title: 'NDA Guarantee' },
      { title: '100% Replacement' },
      { title: 'Highend Systems' },
      { title: 'Free Trial'},
      { title: 'Hiring fees', first: 'true', second: 'Uncertain' },
      { title: 'Hidden Charges', first: 'true', second: 'Uncertain' },
    ]
  };
  const ourData = {
    title: 'Hear From Our Clients',
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['visa.webp'],
        stars: 4,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      }
    ]
  };
  const Questions = [
    "Why should you hire us as a Web Designer?",
    "How can you hire web designer from Octet?",
    "Which companies hire dedicated web designer?",
    "Does hiring website developers come with a trial period?",
    "How does our transparent pricing system work?"
  ];
  const faqData = [
    {
      para: [
        `Octet boasts a skilled, creative, and functional team of web design experts who specialize in crafting engaging and functional interfaces.`,
        `Our web designers have a proven track record of delivering user-centric designs across diverse industries. They are well-versed in design principles and keep themselves updated with the latest design technologies and trends.`,
        `You should hire web designers from Octet to ensure your product remains relevant and competitive.`
      ]
    },
    {
      para: [
        `Hiring Web Designers from Octet involves just 3 easy steps:`,
        `<strong><h4>Share Your Requirements:</h4></strong>`,
        `Tell us about your project needs, including the scope, objectives, target audience, and specific design preferences or technical requirements. This will help us find the most suitable web designer to meet your needs and expectations.`,
        `<strong><h4>Interview Designers:</h4></strong>`,
        `Once we understand your needs, we will share a list of web designers and developers who match your project needs.`,
        `You can then interview and evaluate our designers to assess their skills, experience, and creativity.`,
        `Choose the candidate who best matches your project goals.`,
        `<strong><h4>Onboard and Begin:</h4></strong>`,
        `After hiring web designers from us, onboard our designers onto your project team and provide them access to necessary resources.`,
      ]
    },
    {
      para: [
        `Web designers are in demand across various industries, including technology, e-commerce, finance, SaaS, entertainment, healthcare, education, automotive, telecommunications, and more.`,
        `Whether designing mobile apps, websites, software interfaces, or consumer electronics, our website designers for hire can enhance usability, functionality, and visual appeal for different companies.`,
      ]
    },
    {
      para: [
        `Yes, hiring web Designers from Octet comes with a complimentary 3-day trial period. This allows our clients to experience our approach and see how we can help them find the right candidate for their company.`,
      ]
    },
    {
      para: [
        `At our company, we believe in transparency regarding pricing. We understand the importance of knowing what you're paying for without any surprises, so we offer a clear breakdown of costs for all our products and services.`,
        `Our pricing model is designed to eliminate hidden fees and provide customizable solutions tailored to your unique needs. By hiring web designers from us, you can trust that you're getting the most value for your money.`,
      ]
    },
  ];
  const commonContent5 = {
    title: "Hire Website Developer for the best Quality Ratio",
    para: 'Our talented web designers for hire offer a range of web design services to produce results that have a significant business impact.',
    content: [
      {
        title: "User Experience (UX) Design",
        text: "UX design focuses on creating websites that are intuitive and user-friendly. Hiring web designers will help you understand user behavior, design user interfaces that are easy to navigate, and conduct usability testing to ensure a positive user experience.",
      },
      {
        title: "Website Redesign",
        text: "Website redesign involves updating an existing website to improve its appearance, functionality, and performance. Our website designers for hire modernize the design, enhance the user experience, optimize search engines, and ensure the site is mobile-friendly..",
      },
      {
        title: "Graphic Design and Branding",
        text: "Hire dedicated web designer for creating visual elements representing a brand’s identity. They design logos, business cards, brochures, and other marketing materials that maintain a consistent look and feel across all platforms.",
      },
      {
        title: "WordPress Development",
        text: "Our web designers for hire develop custom WordPress themes tailored to your brand and business needs. They create and integrate custom plugins and extensions to enhance your website’s functionality.",
      },
      {
        title: "E-commerce Web Design",
        text: "Hire professional web designer to focus on creating online stores that are attractive, easy to navigate, and secure. Our web design specialists integrate shopping carts, payment gateways, product catalogs, and inventory management systems to facilitate smooth and secure customer transactions.",
      },
      {
        title: "CMS Development",
        text: "CMS development involves creating a website that allows clients to manage and update their content without technical expertise easily. Popular CMS platforms include WordPress, Joomla, and Drupal. Our web design experts provide custom theme development, plugin integration, and training on how to use the CMS effectively.",
      },
    ],
  };
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "Why should you hire us as a Web Designer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Octet boasts a skilled, creative, and functional team of web design experts who specialize in crafting engaging and functional interfaces. 
          
          
          Our web designers have a proven track record of delivering user-centric designs across diverse industries. They are well-versed in design principles and keep themselves updated with the latest design technologies and trends. 
          
          
          You should hire web designers from Octet to ensure your product remains relevant and competitive."
              }
            },{
              "@type": "Question",
              "name": "How can you hire web designer from Octet?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Hiring Web Designers from Octet involves just 3 easy steps:
          
          
          Share Your Requirements:
          Tell us about your project needs, including the scope, objectives, target audience, and specific design preferences or technical requirements. This will help us find the most suitable web designer to meet your needs and expectations.
          
          
          Interview Designers:
          Once we understand your needs, we will share a list of web designers and developers who match your project needs.
          You can then interview and evaluate our designers to assess their skills, experience, and creativity.
          Choose the candidate who best matches your project goals.
          
          
          Onboard and Begin:
          After hiring web designers from us, onboard our designers onto your project team and provide them access to necessary resources."
              }
            },{
              "@type": "Question",
              "name": "Which companies hire dedicated web designer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Web designers are in demand across various industries, including technology, e-commerce, finance, SaaS, entertainment, healthcare, education, automotive, telecommunications, and more.
          
          
          Whether designing mobile apps, websites, software interfaces, or consumer electronics, our website designers for hire can enhance usability, functionality, and visual appeal for different companies."
              }
            },{
              "@type": "Question",
              "name": "Does hiring website developers come with a trial period?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Yes, hiring web Designers from Octet comes with a complimentary 3-day trial period. This allows our clients to experience our approach and see how we can help them find the right candidate for their company."
              }
            },{
              "@type": "Question",
              "name": "How does our transparent pricing system work?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "At our company, we believe in transparency regarding pricing. We understand the importance of knowing what you're paying for without any surprises, so we offer a clear breakdown of costs for all our products and services. 
          Our pricing model is designed to eliminate hidden fees and provide customizable solutions tailored to your unique needs. By hiring web designers from us, you can trust that you're getting the most value for your money."
              }
            }]
          }                                    
        `}
      </script>
    </Helmet>
      <Layout hire="Hire Our Web Designers">
        <div>
          <HireBanner data={banner} pageName="Hire Web Designer" />
          <section className="py-[40px]">
            <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="lg:h-[100px] md:h-[80px] h-[60px]">
                <div className="flex items-center">
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                  </div>
                </div>
                <div className="flex items-center">
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                  </div>
                  <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                    <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <SwiperTabs data={tabsData} normal light />
          <CommonServiceComponent data={commonContent5} mode={"light"} hire />
          <ContentCommon dark data={commonContent} />
          <Reports data={reports} />
          <ContentCommon data={commonContent2} dark />
          <WorkCollaboration grey content={content} title={`Hire Dedicated Web Designer <span class="h1-span">for your Industry needs</span>`} text={``} />
          <ContentCommon data={commonContent3} big reverse />
          {/* <ContentCommon data={commonContent4} big /> */}
          {/* <OurWorkslef data={ourData} /> */}
          <Cta lgtextClass="lg:text-[50px]" data={cta} />
          <Faq section Questions={Questions} faqData={faqData} des={'Explore common queries about our web design services, hiring web designers, pricing, and processes to gain clarity and confidence in your decision-making journey.'} />
        </div>
      </Layout>
    </>
  );
};

export default HirePage;
export const Head = () => (
  <Seo
    title="Hire Web Designer | Hire Dedicated Web Designer - Octet"
    description="Hire web designer from Octet for lightning-fast, creative, original and top-notch web designs tailored to your business needs."
  />
);